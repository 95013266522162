<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
        <b-img
          fluid
          :src="logoUrl"
          alt="Muqaym"
          style="height: 50px; width: auto"
        />
        <h2 class="brand-text text-primary ml-1">
          <!-- {{ $t('muqaym') }} -->
        </h2>
      </b-link>

        <b-card-title class="mb-1">
          هل نسيت كلمة السر؟🔒
        </b-card-title>
        <b-card-text class="mb-2">
          أدخل بريدك الإلكتروني وسنرسل إليك تعليمات لإعادة تعيين كلمة المرور الخاصة بك
        </b-card-text>

        <!-- form -->
        <validation-observer ref="sendResetLink">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="email"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              إرسال رابط إعادة تعيين
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> العودة إلى تسجيل الدخول
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, validEmail } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      // validation
      required,
      validEmail,
    }
  },
  
  methods: {
    validationForm() {
        this.$refs.sendResetLink.validate().then(async (success) => {
        if (success) {
          try {
              await this.$http.post('/api/forgot-password', {
              email: this.email,
            });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم ارسال الرابط بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
           } catch (error) {
            if (!error.response) console.log(error);
            this.$refs.loginForm.setErrors(error.response.data.errors ?? []);
            this.showAlertMessage = true;
            this.errorMessage = error.response.data.message;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                text: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
